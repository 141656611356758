
import {PropType, defineComponent} from 'vue';
import InviteModal from '@/components/teams/InviteModal.vue';
import Team from '@/models/Team';

export default defineComponent({
    components: {
        InviteModal,
    },
    props: {
        team: {
            type: Object as PropType<Team>,
            required: true,
        },
    },
});
