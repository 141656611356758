
import {PropType, defineComponent, ref} from 'vue';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import FormGroup from '@/components/common/form/FormGroup.vue';
import Modal from '@/components/common/Modal.vue';
import TeamUser from '@/models/TeamUser';
import User from '@/models/User';
import {hideModal} from '@/library/helpers';
import pick from 'lodash/pick';

export default defineComponent({
    components: {
        BtnResource,
        FormGroup,
        Modal,
    },
    props: {
        name: {
            type: String,
            default: 'team-users-rename',
        },
        teamUser: {
            type: Object as PropType<TeamUser>,
            required: true,
        },
    },
    emits: [
        'updated-user',
    ],
    setup(props) {
        return {
            editUser: ref<User>(new User(props.teamUser.user?.attributes)),
            saveAttributes: [
                'firstName',
                'lastName',
                'fullName',
            ],
        };
    },
    watch: {
        'teamUser.id': function handler() {
            this.editUser = new User(this.teamUser.user?.attributes);
        },
    },
    methods: {
        async updateDetails() {
            const updateOptions = {
                data: pick(
                    this.editUser.getSaveData(),
                    this.saveAttributes,
                ),
            };

            await this.editUser.update(updateOptions);

            const emitData = pick(
                this.editUser.attributes,
                this.saveAttributes,
            );

            this.$emit('updated-user', emitData);

            hideModal(this.name);
        },
    },
});
