
import {PropType, defineComponent} from 'vue';
import CollectionTable from '@/components/common/model-collection/CollectionTable.vue';
import OptionsDropdown from '@/components/teamUsers/OptionsDropdown.vue';
import ProfilePicture from '@/components/users/ProfilePicture.vue';
import Role from '@/library/enumerations/Role';
import Team from '@/models/Team';
import TimeAgo from '@/components/common/TimeAgo.vue';

export default defineComponent({
    components: {
        CollectionTable,
        ProfilePicture,
        OptionsDropdown,
        TimeAgo,
    },
    props: {
        headers: {
            type: Array as PropType<string[]>,
            default: () => [
                'name',
                'email',
                'joined',
                'options',
            ],
        },
        team: {
            type: Object as PropType<Team>,
            required: true,
        },
    },
    methods: {
        filteredRoles(roles?: number[]): number[] {
            return roles
                ? roles.filter((role: number) => role !== Role.EDITOR)
                : [];
        },
        badgeBackground(role: number): string {
            return role === Role.ADMIN
                ? 'bg-primary'
                : 'bg-success';
        },
        translateRole(role: number): string {
            return Role.translateValue(role);
        },
    },
});
