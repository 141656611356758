import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["nav nav-tabs", {'border-bottom-0': _ctx.noBorder}])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
      return (_openBlock(), _createElementBlock("a", {
        key: tab.value,
        class: _normalizeClass(["nav-link cursor-pointer", [tab.active ? 'active' : 'text-muted']]),
        onClick: ($event: any) => (_ctx.$emit('tab-clicked', tab.value))
      }, _toDisplayString(tab.label), 11, _hoisted_1))
    }), 128))
  ], 2))
}