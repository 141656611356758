
import {PropType, defineComponent, ref} from 'vue';
import {hideModal, showModal} from '@/library/helpers';
import Dropdown from '@/components/common/Dropdown.vue';
import {DropdownOption} from '@/components/common/DropdownMenu.vue';
import Modal from '@/components/common/Modal.vue';
import RenameModal from '@/components/teamUsers/RenameModal.vue';
import Role from '@/library/enumerations/Role';
import Team from '@/models/Team';
import TeamUser from '@/models/TeamUser';
import {UserData} from '@/models/User';

interface RoleKey {
    action: string;
    role: number;
}

export default defineComponent({
    components: {
        Dropdown,
        Modal,
        RenameModal,
    },
    props: {
        team: {
            type: Object as PropType<Team>,
            required: true,
        },
        teamUser: {
            type: Object as PropType<TeamUser>,
            required: true,
        },
    },
    setup(props) {
        return {
            removeModalName: ref<string>(`remove-user-${props.teamUser.id}`),
            renameModalName: ref<string>(`rename-user-${props.teamUser.id}`),
            roleKeys: [
                {
                    action: 'TeamLeader',
                    role: Role.ADMIN,
                },
                {
                    action: 'TeamPitcher',
                    role: Role.PITCHER,
                },
            ] as RoleKey[],
        };
    },
    computed: {
        dropdownOptions(): DropdownOption[] {
            const roleOptions = this.roleKeys.map((roleKey: RoleKey) => {
                const roleAction = this.teamUser.roles?.includes(roleKey.role) ? 'remove' : 'make';

                return {
                    key: roleKey.action,
                    label: this.$t(`teamUsers.actions.${roleAction}${roleKey.action}`),
                } as DropdownOption;
            });

            return [
                ...roleOptions,
                {
                    key: 'renameUser',
                    label: this.$t('teamUsers.actions.renameUser'),
                },
                {
                    key: 'removeUser',
                    label: this.$t('teamUsers.actions.removeUserFromTeam'),
                },
            ];
        },
    },
    methods: {
        getTeamUserRoles(toggleRole: number): number[] {
            if (!this.teamUser.roles) {
                return [toggleRole];
            }

            // If the user does not have the role, add it to the user's roles.
            if (!this.teamUser.roles.includes(toggleRole)) {
                return [
                    ...this.teamUser.roles,
                    toggleRole,
                ];
            }

            // Otherwise, find the role index.
            const roleIndex = this.teamUser.roles.findIndex((role: number) => role === toggleRole);

            const roles = [...this.teamUser.roles];

            // Remove the role from the user's roles.
            roles.splice(roleIndex, 1);

            return roles;
        },
        onOptionSelected(action: string) {
            if (action === 'removeUser') {
                showModal(this.removeModalName);

                return;
            }

            if (action === 'renameUser') {
                showModal(this.renameModalName);

                return;
            }

            this.updateTeamUser(action);
        },
        removeUser() {
            hideModal(this.removeModalName);

            this.updateTeamUser('removeUser');
        },
        async updateTeamUser(action: string) {
            if (action === 'removeUser') {
                this.team.set('detach', [this.teamUser.id!]);
            } else {
                const roleKey = this.roleKeys.find((roleKey: RoleKey) => roleKey.action === action);

                this.team.set('sync', [{
                    id: this.teamUser.id,
                    roles: this.getTeamUserRoles(roleKey!.role),
                }]);
            }

            try {
                await this.team.update({
                    url: `/teams/${this.teamUser.modelId}`,
                });
            } catch (e: any) {
                if (!e.response || !e.response.data.errors) throw e;

                return;
            }

            this.team.set('detach', []);
            this.team.set('sync', []);

            this.team.resetStatus();
        },
        updateUserName(userData: Partial<UserData>) {
            const teamUser = this.team.teamUsers.firstWhere('id', this.teamUser.id);

            teamUser.user?.fill(userData);
        },
    },
});
