
import {PropType, defineComponent} from 'vue';
import Team from '@/models/Team';
import TeamForm from '@/components/teams/teams/Form.vue';
import TeamType from '@/library/enumerations/TeamType';

export default defineComponent({
    components: {
        TeamForm,
    },
    props: {
        team: {
            type: Object as PropType<Team>,
            required: true,
        },
    },
    emits: [
        'team-saved',
    ],
    setup() {
        return {
            TeamType,
        };
    },
});
