
import {PropType, defineComponent, ref} from 'vue';
import BtnInviteModal from '@/components/teams/BtnInviteModal.vue';
import {Portal} from 'portal-vue';
import Team from '@/models/Team';
import {TeamUsers} from '@/models/TeamUser';
import TeamUsersTable from '@/components/teamUsers/Table.vue';

export default defineComponent({
    components: {
        BtnInviteModal,
        Portal,
        TeamUsersTable,
    },
    props: {
        hideNavbarTopLink: {
            type: Boolean,
            default: false,
        },
        team: {
            type: Object as PropType<Team>,
            required: true,
        },
    },
    setup(props) {
        return {
            teamUsers: ref<TeamUsers>(new TeamUsers()),
            options: {
                url: `/teams/${props.team.id}/team-users`,
            },
        };
    },
});
