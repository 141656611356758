
import {PropType, defineComponent} from 'vue';

export interface NavTabItem {
    label: string;
    value: string|number;
    active?: boolean;
}

/**
 * Creates navigation tab links.
 * Useful for displaying different sections on the same page.
 * Active tab links will be styled differently to indicate active status.
 * Emits 'tab-clicked' with the value of the tab that is clicked.
 */
export default defineComponent({
    props: {
        noBorder: {
            type: Boolean,
            default: false,
        },
        tabs: {
            type: Array as PropType<NavTabItem[]>,
            required: true,
        },
    },
    emits: [
        'tab-clicked',
    ],
});
