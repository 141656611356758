import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PillInputEmail = _resolveComponent("PillInputEmail")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_BtnResource = _resolveComponent("BtnResource")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    name: _ctx.name,
    "ok-title": _ctx.$t('teams.actions.inviteMembers'),
    title: _ctx.$t('teams.edit.members.inviteTitle')
  }, {
    "ok-button": _withCtx(({title}) => [
      _createVNode(_component_BtnResource, {
        "data-test-id": "btn-invite-modal-submit",
        resource: _ctx.team,
        onClick: _ctx.sendInvites
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(title), 1)
        ]),
        _: 2
      }, 1032, ["resource", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendInvites && _ctx.sendInvites(...args)), ["prevent"]))
      }, [
        _createVNode(_component_FormGroup, {
          errors: _ctx.team.errors,
          label: _ctx.$t('teams.edit.members.memberEmails')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PillInputEmail, {
              modelValue: _ctx.emails,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emails) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["errors", "label"])
      ], 32)
    ]),
    _: 1
  }, 8, ["name", "ok-title", "title"]))
}