import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row text-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_BtnResource = _resolveComponent("BtnResource")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    name: _ctx.name,
    "ok-title": _ctx.$t('teamUsers.actions.changeName'),
    title: _ctx.$t('teamUsers.edit.renameTitle')
  }, {
    "ok-button": _withCtx(({title}) => [
      _createVNode(_component_BtnResource, {
        "data-test-id": "btn-rename-modal-submit",
        resource: _ctx.editUser,
        onClick: _ctx.updateDetails
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(title), 1)
        ]),
        _: 2
      }, 1032, ["resource", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateDetails && _ctx.updateDetails(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_FormGroup, {
            modelValue: _ctx.editUser.firstName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editUser.firstName) = $event)),
            class: "col-6",
            "data-test-id": "input-first-name",
            "error-key": "firstName",
            errors: _ctx.editUser.errors,
            label: _ctx.$t('teamUsers.edit.firstName'),
            name: "firstName"
          }, null, 8, ["modelValue", "errors", "label"]),
          _createVNode(_component_FormGroup, {
            modelValue: _ctx.editUser.lastName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editUser.lastName) = $event)),
            class: "col-6",
            "data-test-id": "input-last-name",
            "error-key": "lastName",
            errors: _ctx.editUser.errors,
            label: _ctx.$t('teamUsers.edit.lastName'),
            name: "lastName"
          }, null, 8, ["modelValue", "errors", "label"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["name", "ok-title", "title"]))
}