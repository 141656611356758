import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "mx-6" }
const _hoisted_3 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfilePicture = _resolveComponent("ProfilePicture")!
  const _component_TimeAgo = _resolveComponent("TimeAgo")!
  const _component_OptionsDropdown = _resolveComponent("OptionsDropdown")!
  const _component_CollectionTable = _resolveComponent("CollectionTable")!

  return (_openBlock(), _createBlock(_component_CollectionTable, {
    collection: _ctx.team.teamUsers,
    headers: _ctx.headers,
    "no-results-text": _ctx.$t('teamUsers.words.noResults'),
    "prevent-row-click": ""
  }, {
    name: _withCtx(({row}) => [
      _createElementVNode("td", null, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ProfilePicture, {
            class: "square rounded-circle",
            user: row.user
          }, null, 8, ["user"]),
          _createElementVNode("span", _hoisted_2, _toDisplayString(row.user.displayName || _ctx.$t('users.guest')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredRoles(row.roles), (role) => {
            return (_openBlock(), _createElementBlock("span", {
              key: `user-role-${row.user.id}-${role}`,
              class: _normalizeClass(["badge badge-faded ms-2", _ctx.badgeBackground(role)])
            }, _toDisplayString(_ctx.translateRole(role)), 3))
          }), 128))
        ])
      ])
    ]),
    email: _withCtx(({row}) => [
      _createElementVNode("td", null, _toDisplayString(row.user.email), 1)
    ]),
    joined: _withCtx(({row}) => [
      _createElementVNode("td", null, [
        _createVNode(_component_TimeAgo, {
          date: row.createdAt
        }, null, 8, ["date"])
      ])
    ]),
    options: _withCtx(({row}) => [
      _createElementVNode("td", _hoisted_3, [
        _createVNode(_component_OptionsDropdown, {
          team: _ctx.team,
          "team-user": row
        }, null, 8, ["team", "team-user"])
      ])
    ]),
    _: 1
  }, 8, ["collection", "headers", "no-results-text"]))
}