import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "ms-6 me-auto" }
const _hoisted_3 = { class: "px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_BtnInviteModal = _resolveComponent("BtnInviteModal")!
  const _component_Portal = _resolveComponent("Portal")!
  const _component_TeamUsersTable = _resolveComponent("TeamUsersTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.hideNavbarTopLink)
      ? (_openBlock(), _createBlock(_component_Portal, {
          key: 0,
          to: "navbar-top-content"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_RouterLink, {
                class: "btn px-0",
                to: {name: 'teams.index'}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Fa, { icon: "arrow-left" })
                ]),
                _: 1
              }, 8, ["to"]),
              _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.team.name != null ? _ctx.team.name : _ctx.$t('teams.edit.title')), 1),
              _createVNode(_component_BtnInviteModal, { team: _ctx.team }, null, 8, ["team"])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_TeamUsersTable, _mergeProps(_ctx.$attrs, {
        options: _ctx.options,
        team: _ctx.team
      }), null, 16, ["options", "team"])
    ])
  ]))
}